<template>
  <div class="features_wrapper">
    <main id="feature_main">
      <section class="parallax feature_bg">
        <!-- <div class="pb-16">
          <v-container>
            <v-row class="my-8">
              <v-col class="text-center">
                <h1 class="text-h2 mb-8">
                  Features
                </h1>
                <p class="text-h5">
                  Airfield management software that makes your life easy.
                </p>
              </v-col>
            </v-row>
          </v-container>
        </div> -->
        <v-row   
          vertical-align="bottom"
          class="override_home_welcome"
        >
          <v-col
            align="end" 
            class="text-center"
            cols="12"
          >
            <v-row class="my-8">
              <v-col class="text-center">
                <h1 class="text-h3 mb-8 text-center force-center-title">
                  Our Features
                </h1>
                <p class="text-h5">
                  Airfield management software that makes your life easy.
                </p>
                <br>
                <v-btn
                  color="homebuttonbg"
                  class="homebuttoncolour--text"
                  x-large
                  @click="$vuetify.goTo('#section_feature_1', options)"
                >
                  <v-icon left>
                    mdi-rocket-launch
                  </v-icon>
                  see our main features
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </section>
      <section
        id="section_feature_1"
        class="no-parallax smaller_no_parallax_content"
      >
        <div class="pb-16">
          <v-container>
            <v-row>
              <v-col
                md="8"
                offset-md="2"
              >
                <div class="py-8">
                  <h2 class="text-h4 mt-4 mb-6">
                    <v-icon
                      size="32"
                      left
                      style="vertical-align:baseline;"
                      color="homeiconcolour"
                    >
                      mdi-auto-fix
                    </v-icon>
                    Manage your airfield operations anywhere.
                  </h2>
                  <p class="text-body-1">
                    Sign in to your free AirfieldHub Dashboard from any computer, tablet,
                    or mobile, and handle your arrivals and departures from anywhere.
                  </p>
                  <ul>
                    <li class="text-body-2 my-1">
                      <v-icon
                        left
                        color="green darken-1"
                      >
                        mdi-check
                      </v-icon>
                      Works on any device
                    </li>
                    <li class="text-body-2 my-1">
                      <v-icon
                        left
                        color="green darken-1"
                      >
                        mdi-check
                      </v-icon>
                      Simple and intuitive to use
                    </li>
                    <li class="text-body-2 my-1">
                      <v-icon
                        left
                        color="green darken-1"
                      >
                        mdi-check
                      </v-icon>
                      Light or Dark theme, you choose
                    </li>
                  </ul>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </section>
      <section class="parallax feature_bg2 smaller_no_parallax_content">
        <div class="pb-16">
          <v-container>
            <v-row class="my-8">
              <v-col class="text-center">
                <div class="py-8">
                  <h2 class="text-h4 mt-4 mb-6">
                    <v-icon
                      size="32"
                      left
                      color="#FFFFFF"
                      style="vertical-align:baseline;"
                    >
                      mdi-airplane-landing
                    </v-icon>
                    Accept online PPR.
                  </h2>
                  <p class="text-body-1">
                    Add our online PPR form to your website to start receiving PPR you can
                    approve with one click.
                  </p>
                  <ul>
                    <li class="text-body-2 my-1">
                      <v-icon
                        left
                        color="green darken-1"
                      >
                        mdi-check
                      </v-icon>
                      Digitally records and stores all arrival details for your logs
                    </li>
                    <li class="text-body-2 my-1">
                      <v-icon
                        left
                        color="green darken-1"
                      >
                        mdi-check
                      </v-icon>
                      Save time with one click approval or reject with a message
                    </li>
                    <li class="text-body-2 my-1">
                      <v-icon
                        left
                        color="green darken-1"
                      >
                        mdi-check
                      </v-icon>
                      Auto-approval for when your website briefing says it all
                    </li>
                    <li class="text-body-2 my-1">
                      <v-icon
                        left
                        color="green darken-1"
                      >
                        mdi-check
                      </v-icon>
                      Pilots may cancel PPR with one click keeping you informed
                    </li>
                  </ul>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </section>
      <section class="no-parallax smaller_no_parallax_content">
        <div class="pb-16">
          <v-container>
            <v-row>
              <v-col
                md="8"
                offset-md="2"
              >
                <div class="py-8">
                  <h2 class="text-h4 mt-4 mb-6">
                    <v-icon
                      size="32"
                      left
                      color="homeiconcolour"
                      style="vertical-align:baseline;"
                    >
                      mdi-airplane-landing
                    </v-icon>
                    Accept online PPR.
                  </h2>
                  <p class="text-body-1">
                    Add our online PPR form to your website to start receiving PPR you can
                    approve with one click.
                  </p>
                  <ul>
                    <li class="text-body-2 my-1">
                      <v-icon
                        left
                        color="green darken-1"
                      >
                        mdi-check
                      </v-icon>
                      Digitally records and stores all arrival details for your logs
                    </li>
                    <li class="text-body-2 my-1">
                      <v-icon
                        left
                        color="green darken-1"
                      >
                        mdi-check
                      </v-icon>
                      Save time with one click approval or reject with a message
                    </li>
                    <li class="text-body-2 my-1">
                      <v-icon
                        left
                        color="green darken-1"
                      >
                        mdi-check
                      </v-icon>
                      Auto-approval for when your website briefing says it all
                    </li>
                    <li class="text-body-2 my-1">
                      <v-icon
                        left
                        color="green darken-1"
                      >
                        mdi-check
                      </v-icon>
                      Pilots may cancel PPR with one click keeping you informed
                    </li>
                  </ul>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </section>
      <section class="parallax feature_bg3 smaller_no_parallax_content">
        <div class="pb-16">
          <v-container>
            <v-row>
              <v-col
                md="8"
                offset-md="2"
              >
                <div class="py-8">
                  <h2 class="text-h4 mt-4 mb-6">
                    <v-icon
                      size="32"
                      left
                      color="#FFFFFF"
                      style="vertical-align:baseline;"
                    >
                      mdi-airplane-takeoff
                    </v-icon>
                    Booking Out online.
                  </h2>
                  <p class="text-body-1">
                    Add our online Booking Out form to your website and make your logbooks
                    digital.
                  </p>
                  <ul>
                    <li class="text-body-2 my-1">
                      <v-icon
                        left
                        color="green darken-1"
                      >
                        mdi-check
                      </v-icon>
                      Digitally records and stores all departure details for your logs
                    </li>
                    <li class="text-body-2 my-1">
                      <v-icon
                        left
                        color="green darken-1"
                      >
                        mdi-check
                      </v-icon>
                      Simple and easy form for pilots to book out in just a few clicks
                    </li>
                    <li class="text-body-2 my-1">
                      <v-icon
                        left
                        color="green darken-1"
                      >
                        mdi-check
                      </v-icon>
                      Pilots may cancel their departure with one click keeping you
                      informed
                    </li>
                  </ul>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </section>
      <section class="no-parallax smaller_no_parallax_content">
        <div class="pb-16">
          <v-container>
            <v-row>
              <v-col
                md="8"
                offset-md="2"
              >
                <div class="py-8">
                  <h2 class="text-h4 mt-4 mb-6">
                    <v-icon
                      size="32"
                      left
                      color="homeiconcolour"
                      style="vertical-align:baseline;"
                    >
                      mdi-credit-card-lock-outline
                    </v-icon>
                    Take card payments for fees and charges.
                  </h2>
                  <p class="text-body-1">
                    Online card payments make it easy for pilots to pay
                    landing fees and overnight parking from any device, with Apple Pay, 
                    Google Pay, and credit/debit cards
                  </p>
                  <ul>
                    <li class="text-body-2 my-1">
                      <v-icon
                        left
                        color="green darken-1"
                      >
                        mdi-check
                      </v-icon>
                      Secure online payments straight to your bank account, saving trips
                      to the bank
                    </li>
                    <li class="text-body-2 my-1">
                      <v-icon
                        left
                        color="green darken-1"
                      >
                        mdi-check
                      </v-icon>
                      Reduce missed payments from out-of-hours landings, lack of cash, or unattended airfield
                    </li>
                    <li class="text-body-2 my-1">
                      <v-icon
                        left
                        color="green darken-1"
                      >
                        mdi-check
                      </v-icon>
                      Automatically calculated landing fees based on aircraft MTOW or type
                      (other rules coming soon)
                    </li>
                    <li class="text-body-2 my-1">
                      <v-icon
                        left
                        color="green darken-1"
                      >
                        mdi-check
                      </v-icon>
                      Payment links and QR codes to request landing fee payment
                    </li>
                    <li class="text-body-2 my-1">
                      <v-icon
                        left
                        color="green darken-1"
                      >
                        mdi-check
                      </v-icon>
                      No pilot account required, just simple card and wallet payments
                    </li>
                  </ul>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </section>
      <section class="parallax feature_bg4 smaller_no_parallax_content">
        <div class="pb-16">
          <v-container>
            <v-row>
              <v-col
                md="8"
                offset-md="2"
              >
                <div class="py-8">
                  <h2 class="text-h4 mt-4 mb-6">
                    <v-icon
                      size="32"
                      left
                      color="#FFFFFF"
                      style="vertical-align:baseline;"
                    >
                      mdi-email-fast-outline
                    </v-icon>
                    Fast setup.
                  </h2>
                  <p class="text-body-1">
                    Create your airfield in minutes, with simple sign-up to accept
                    payments online with world-class payment provider Stripe.
                  </p>
                  <ul>
                    <li class="text-body-2 my-1">
                      <v-icon
                        left
                        color="green darken-1"
                      >
                        mdi-check
                      </v-icon>
                      Magic link sign in, just your email needed, no more insecure
                      passwords
                    </li>
                    <li class="text-body-2 my-1">
                      <v-icon
                        left
                        color="green darken-1"
                      >
                        mdi-check
                      </v-icon>
                      Start logging book out and PPR within minutes
                    </li>
                  </ul>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </section>
      <section class="no-parallax smaller_no_parallax_content">
        <div class="pb-16">
          <v-container>
            <div class="d-flex flex-column justify-space-between align-center my-8">
              <v-btn
                x-large
                color="homebuttonbg"
                class="homebuttoncolour--text"
                to="/register"
              >
                <v-icon left>
                  mdi-rocket-launch
                </v-icon>
                Sign up for free today
              </v-btn>
            </div>
          </v-container>
        </div>
      </section>
      <section class="no-parallax shorter_content">
        <footer-bar />
      </section>
    </main>
  </div>
</template>

<script>
import FooterBar from "../components/FooterBar.vue";

export default {
  name: "Features",
  components: { FooterBar },
  computed: {
    options () {
        return {
          duration: 600,
          offset: 100,
          easing: "easeInOutCubic",
          container: "#feature_main"
        }
      },
  }

};
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
}


.py-8{
  ul, p, h2{
    display: block;
    width: 100%;
    text-align: center;
  }
}
.little_image{
  max-width: 100%;
  max-height: 100%;
}
</style>
